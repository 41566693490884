// header shrink-------------------------------------------------------------------
function init() {
    window.addEventListener('scroll', function (e) {
        var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 0,
                header = document.querySelector("header");
        if (distanceY > shrinkOn) {
            classie.add(header, "smaller");
        } else {
            if (classie.has(header, "smaller")) {
                classie.remove(header, "smaller");
            }
        }
    });
}
window.onload = init();

 jQuery(document).ready(function(){
                    jQuery(".menuzord-nav").menuzord({
                        align: ""
                    });
                });


$(document).ready(function() {
        $('.popup-with-form').magnificPopup({
          type: 'inline',
          preloader: false,
          focus: '#name',

          // When elemened is focused, some mobile browsers in some cases zoom in
          // It looks not nice, so we disable it:
          callbacks: {
            beforeOpen: function() {
              if($(window).width() < 700) {
                this.st.focus = false;
              } else {
                this.st.focus = '#name';
              }
            }
          }
        });
      });

	  $('.gallery').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        delegate: 'a.zoom', // the selector for gallery item
        type: 'image',
        gallery: {
          enabled:true
        }
    });
});


      $(document).ready(function() {
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 00,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,

          fixedContentPos: false
        });
      });


$(".header-right-patch").css({'width':($(".right-container").width()+'px')});
$(".slider").css({'margin-right':($(".right-container").width()+'px')});

$(".right-patch").css({'width':($(".right-container").width()+'px')});
$(".slider-nav .item").css({'height':($(".form-container").height()+'px')});
<!--refresh on resize -->
var before = $(this).width();

$(window).resize(function() {
    var after = $(this).width();
   if (after != before) {
      location.reload()
   }
})

$(document).on('ready', function() {
  $('.slider-nav').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    focusOnSelect: false,
    centerMode: false,
    variableWidth: false,
    responsive: [
    {
      breakpoint: 480,
      settings: {
      slidesToShow: 1,
      variableWidth: false,
       arrows: false,
      centerMode: false,
      adaptiveHeight: true
      }
    }
    ]
  });
  $('.adbanner-slide').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
    {
      breakpoint: 575,
      settings: {
      slidesToShow: 1
      }
    }
    ]
  });
  $('.destination-slide').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    responsive: [
    {
      breakpoint: 992,
      settings: {
      slidesToShow: 3
      }
    },
    {
      breakpoint: 575,
      settings: {
      slidesToShow: 1
      }
    }
    ]
  });
});


$(window).load(function(){
    $(".input-effect input").val("");
    $(".input-effect input").focusout(function(){
        if($(this).val() != ""){
            $(this).addClass("has-content");
        }else{
            $(this).removeClass("has-content");
        }
    })
});

$( function() {
  $( ".datepicker" ).datepicker();
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

$(".view-on-map").click(function(){
$(".item-map").toggleClass("item-map-expanded");
});

$(document).ready(function () {
$(".read-more-content").hide();
$(".show_hide-read-more").on("click", function () {
    var txt = $(".read-more-content").is(':visible') ? 'Read More +' : 'Read Less -';
    $(".show_hide-read-more").text(txt);
    $('.read-more-content').slideToggle(200);
});
});

(function() {
    var cssFa = document.createElement('link');
    cssFa.href = 'https://use.fontawesome.com/releases/v5.6.3/css/all.css';
    cssFa.rel = 'stylesheet';
    cssFa.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(cssFa);
})();

(function() {
    var icomoonTtf = document.createElement('link');
    icomoonTtf.href = baseurl+'assets/fonts/icomoon.ttf?uqwpq7';
    icomoonTtf.rel = 'stylesheet';
    icomoonTtf.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(icomoonTtf);
		var icomoonWoff = document.createElement('link');
		icomoonWoff.href = baseurl+'assets/fonts/icomoon.woff?uqwpq7';
		icomoonWoff.rel = 'stylesheet';
		icomoonWoff.type = 'text/css';
		document.getElementsByTagName('head')[0].appendChild(icomoonWoff);
})();
